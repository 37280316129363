<script setup lang="ts">

</script>

<template>
  <div class="logo flex gap-x-4 items-center justify-center p-2 rounded-xl">
    <img src="/favicon.ico" alt="Telescope Logo" class="w-10">
    <span class="text-2xl font-mono font-semibold text-blue-500">doorbeen</span>
  </div>
</template>

<style scoped>

</style>